<script setup lang="ts">
import type { Collection } from '~/types/inspirations/types'
import type { Locale } from 'lc-services/types'

const props = withDefaults(
  defineProps<{
    collections: Collection[]
    destiId?: number | null
    housesCount?: number
    page: string
    propertyType?: string | null
    searchButtonVisible?: boolean
    title: string
    titleOnTop?: boolean
  }>(),
  {
    destiId: null,
    housesCount: 0,
    propertyType: null,
    searchButtonVisible: true,
    titleOnTop: true,
  },
)
defineSlots<{ 'header'(): any }>()

const { locale } = useI18n()
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { isMobile } = useBreakpoint()
const selectedTab = ref(1)

const activeLoop = computed(() => {
  return props.collections[selectedTab.value - 1].houses.length > 2
})

const swiperOptions = computed(() => ({
  breakpoints: {
    576: {
      slidesPerView: 1.5,
    },
    768: {
      slidesPerView: 1.8,
    },
  },
  centeredSlides: false,
  loop: activeLoop.value,
  slidesPerView: 1.2,
  spaceBetween: 16,
}))
const roundedHousesCount = computed(
  () => 10 * Math.floor(props.housesCount / 10),
)
const goToSearch = computed(() => {
  let res = localePath('search')

  if (props.destiId) {
    res = localePath({
      name: 'search',
      query: { destinationId: props.destiId },
    })
  }

  return res
})

const onTabSelected = (tabNumber: number) => {
  selectedTab.value = tabNumber
}

const trackFeaturedHouses = (id: string) => {
  const selectedCollection = props.collections[selectedTab.value - 1]
  const collectionName = selectedCollection.tab.en
  const selectedHouse = selectedCollection.houses.find(
    (house) => house.id === id,
  )

  trackEvent({
    event: 'promo_list_click',
    label: selectedHouse?.name,
    product_list_title: props.title,
    product_list_subtitle: collectionName,
  })
}

const tabNumber = (tab: number) => {
  return tab < 3 ? 1 : 3
}

const showBtnFacetFilters = computed(() => {
  const tab = props.collections[selectedTab.value - 1]

  return Boolean(tab?.btnRedirect) && Boolean(tab?.linkRedirect)
})

const showBlockUnderFeaturedHouses = computed(() => {
  return (
    (Boolean(props.propertyType) && props.searchButtonVisible) ||
    Boolean(props.collections[selectedTab.value - 1]?.description) ||
    showBtnFacetFilters.value
  )
})

const linkRedirect = (collection: Collection) => {
  if (collection.linkRedirect.startsWith('https')) {
    return collection.linkRedirect
  }
  return `/${locale.value}/${collection.linkRedirect}`
}
</script>

<template>
  <section>
    <BaseContainer class="featured-houses__heading">
      <BaseRow
        v-if="isMobile || titleOnTop"
        class="featured-houses__header justify-center"
      >
        <BaseCol cols="24" sm="20" lg="16">
          <h2 class="featured-houses__title featured-houses__title--top">
            {{ title }}
          </h2>
          <slot name="header" />
        </BaseCol>
      </BaseRow>

      <div class="featured-houses__row flex items-end">
        <BaseCol
          v-if="!isMobile && !titleOnTop"
          class="featured-houses__col--title xl:order-2"
          cols="24"
          xl="8"
        >
          <h2 class="featured-houses__title featured-houses__title--within">
            {{ title }}
          </h2>
        </BaseCol>

        <BaseCol
          v-for="tab in 4"
          :key="tab"
          :class="['featured-houses__col--tabs', `xl:order-${tabNumber(tab)}`]"
          md="6"
          :xl="titleOnTop ? '6' : '4'"
          @click="onTabSelected(tab)"
        >
          <h3
            :class="[
              'featured-houses__tab text-lg ',
              { 'featured-houses__tab--active': selectedTab === tab },
            ]"
          >
            {{ collections[tab - 1].tab[locale as Locale] }}
          </h3>
        </BaseCol>
      </div>
    </BaseContainer>

    <div class="featured-houses__content">
      <BaseContainer class="featured-houses__grid">
        <BaseRow>
          <BaseCol
            v-for="house in collections[selectedTab - 1].houses"
            :key="`${selectedTab}-${house.position}`"
          >
            <div>
              <BaseHouseTile
                :page="page"
                :house="house"
                :width="
                  collections[selectedTab - 1].houses.length === 2 ? 608 : 400
                "
                :alt="house.alt || house.name"
                section="featured houses"
                class="featured-houses__grid-house"
                @track-featured-houses="trackFeaturedHouses"
              />
            </div>
          </BaseCol>
        </BaseRow>
      </BaseContainer>

      <VirtualBaseCarousel
        class-name="featured-houses__carousel"
        :slides="collections[selectedTab - 1].houses"
        :navigation="$device.isDesktop"
        :swiper-options="swiperOptions"
        :virtual="false"
      >
        <template #slider-content="{ slide }">
          <div :key="`${selectedTab}-${slide.position}`" class="swiper-slide">
            <BaseHouseTile
              :page="page"
              :house="slide"
              :alt="slide.alt || slide.name"
              section="featured houses"
              @track-featured-houses="trackFeaturedHouses"
            />
          </div>
        </template>
      </VirtualBaseCarousel>
    </div>

    <BaseContainer v-if="showBlockUnderFeaturedHouses">
      <BaseRow
        class="featured-houses-footer flex-col items-center justify-center text-center"
      >
        <i18n-t
          v-if="roundedHousesCount > 0"
          keypath="global.destinations.chooseFromMoreThan"
          class="featured-houses-footer-title text-6xl"
          tag="h4"
        >
          <template #count>
            {{ roundedHousesCount }}
          </template>
        </i18n-t>
        <BaseCol cols="24" md="16" lg="10">
          <p
            v-if="collections[selectedTab - 1].description"
            class="featured-houses-text"
          >
            {{ collections[selectedTab - 1].description }}
          </p>
          <BaseButton
            v-if="propertyType && searchButtonVisible"
            :to="goToSearch"
            color="secondary"
          >
            {{ $t(`global.featured_houses.search_all.${propertyType}`) }}
          </BaseButton>
          <BaseButton
            v-if="showBtnFacetFilters"
            :href="linkRedirect(collections[selectedTab - 1])"
            color="secondary"
          >
            {{ collections[selectedTab - 1].btnRedirect }}
          </BaseButton>
        </BaseCol>
      </BaseRow>

      <BaseRow class="mt-20 justify-center lg:hidden">
        <BaseCol cols="22" md="20">
          <BaseRow>
            <BaseCol>
              <BaseIcon
                :size="3"
                class="featured-houses__quote-icon stroke-none"
                filled
                name="quotes"
              />
            </BaseCol>
          </BaseRow>

          <BaseRow>
            <BaseCol>
              <p class="featured-houses__quote text-font-family-serif">
                {{ $t('global.featured_houses.description') }}
              </p>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseContainer>
  </section>
</template>

<style>
.featured-houses__header {
  @apply flex items-center text-center;
}

.featured-houses__heading {
  margin: 0 auto 2rem auto;
}

.featured-houses__row {
  @apply flex-nowrap justify-between overflow-auto;
  margin: 0 calc(-1 * 1rem);
}

@screen sm {
  .featured-houses__row {
    margin: 0 calc(-1 * 1.5rem);
  }
}

@screen md {
  .featured-houses__row {
    @apply h-auto flex-wrap;
    margin: 0 -0.5rem;
  }
}

.featured-houses__title {
  @apply flex justify-center text-center m-0 pb-10;
}

@screen md {
  .featured-houses__title {
    @apply text-8xl;
  }
}

@screen xl {
  .featured-houses__title {
    @apply p-0;
    margin: 0 0 10px 0;
  }
}

@screen xl {
  .featured-houses__title--top {
    @apply pb-8;
  }
}
.featured-houses__title--within {
  line-height: 1;
}

.featured-houses__col--tabs {
  @apply p-0;
  min-width: 180px;
}

.featured-houses__tab {
  transition: all 0.3s ease-in-out 0s;
  @apply font-sansSerif leading-6 text-gray-600 relative text-center cursor-pointer py-0 px-4;
}

@screen md {
  .featured-houses__tab {
    @apply py-0 px-2;
  }
}

@screen xl {
  .featured-houses__tab {
    padding: 1.35rem 0.75rem 0 0.75rem;
  }
}
.featured-houses__tab:before {
  content: '';
  height: 0.18rem;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out 0s;
  @apply bg-secondary-500 w-0 invisible absolute left-1/2 bottom-0;
}
.featured-houses__tab:after {
  content: '';
  height: 10px;
  @apply w-full block;
}

.featured-houses__tab--active,
.featured-houses__col--tabs:hover .featured-houses__tab {
  @apply text-secondary-500;
}
.featured-houses__tab--active:before,
.featured-houses__col--tabs:hover .featured-houses__tab:before {
  width: 40%;
  @apply visible;
}

.featured-houses__row:hover
  .featured-houses__col--title:not(:hover)
  ~ .featured-houses__col--tabs
  .featured-houses__tab--active:not(:hover):before {
  @apply invisible w-0;
}

.featured-houses__houses-container {
  margin: 1rem auto 0 auto;
}

.featured-houses__content {
  @apply overflow-hidden;
  margin: 3rem 0 3rem 0;
}

.featured-houses__grid {
  @apply hidden;
}

@screen lg {
  .featured-houses__grid {
    @apply block;
  }
}

.featured-houses__grid-house {
  padding: 0.25rem 0;
}

.featured-houses__carousel.swiper {
  @apply px-4;
}

@screen md {
  .featured-houses__carousel.swiper {
    @apply px-8;
  }
}

@screen lg {
  .featured-houses__carousel.swiper {
    @apply hidden;
  }
}

.featured-houses-footer-title {
  @apply my-8 mx-auto;
}

.featured-houses-text {
  @apply mb-8;
}

.featured-houses__quote {
  @apply text-2xl;
}
.featured-houses__quote-icon {
  @apply pb-4;
}
</style>
